@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.quill {
    width: 100%;
    height: 18em !important;
    padding-bottom: 2em;
}

.ql-toolbar {
    border: 1px solid #D6D6D6 !important;
    background-color: #EAEAEA;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.ql-toolbar.ql-snow {
    border-bottom: 2px solid #D6D6D6 !important;
}

.ql-container {
    border: 1px solid #D6D6D6 !important;
    background-color: #EAEAEA;
    color: #5E257D;
    font-size: 1em;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: 'Josefin Sans', 'sans-serif' !important;
}

.ql-snow.ql-toolbar button:hover {
    color: #5E257D99 !important;
}