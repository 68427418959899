.snackbar_container {
    visibility: hidden;
    position: fixed;
    display: flex;
    width:fit-content;
    min-width: 480px;
    height: auto;
    border-radius: 10px;
    padding: 1em;
    align-items: center;
    box-shadow: 
        0 3px 6px rgba(0, 0, 0, 0.16), 
        0 3px 6px rgba(0, 0, 0, 0.23);
    z-index: 100;
}

.snackbar_show {
    visibility: visible;
}

.top_left {
    top: 1rem;
    left: 1.5rem;
    -webkit-animation: fadein 0.5s, fadeout 0.5s;
    animation: fadein 0.5s, fadeout 0.5s;
    animation-duration: 0.8s;
}

.top_right {
    top: 1rem;
    right: 1.5rem;
}

.bottom_left {
    bottom: 1rem;
    left: 1.5rem;
    -webkit-animation: fadein 0.5s, fadeout 0.5s;
    animation: fadein 0.5s, fadeout 0.5s;
}

.bottom_right {
    bottom: 1rem;
    right: 1.5rem;
}

.error {
    background-color: #E33B45;
    color: #333333;
}
.warning {
    background-color: yellow;
}
.info {
    background-color: blue;
}
.success {
    background-color: green;
}

@-webkit-keyframes fadein {
    from {
        left: 0; 
        opacity: 0;
    } to {
        left: 1.5rem; 
        opacity: 1;
    }
}
  
@keyframes fadein {
    from {
        left: 0; 
        opacity: 0;
    } to {
        left: 1.5rem; 
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        left: 1.5rem; 
        opacity: 1;
    } to {
        left: 0; 
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        left: 1.5rem; 
        opacity: 1;
    } to {
        left: 0; 
        opacity: 0;
    }
}

@media screen and (max-width: 450px) {
    .snackbar_container {
        width: 90%
    }
}