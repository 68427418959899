/* Circle Style */
svg.loading_circle {
    width: 1.5em;
    transform-origin: center;
    animation: rotate 2s linear infinite;
    max-width: 5em;
    display: block;
  }
    
  svg.loading_circle circle {
    fill: none;
    stroke: #5E257D;
    stroke-width: 3;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {transform: rotate(360deg); }
  }
  
  @keyframes dash {
    0% {stroke-dasharray: 1, 200; stroke-dashoffset: 0; }
    50% { stroke-dasharray: 90, 200;  stroke-dashoffset: -35px; }
    100% { stroke-dashoffset: -125px; }
  }
    
  /* Bars Style */
  .vertical_loading_bars {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 2em;
  }
  
  .vertical_loading_bars span {
    width: 0.3em;
    height: 1em;
    border-radius: 5px;
    background-color: #5E257D;
  }
  
  .vertical_loading_bars span:nth-of-type(1) {
    animation: grow 1s -0.45s ease-in-out infinite;
  }
  
  .vertical_loading_bars span:nth-of-type(2) {
    animation: grow 1s -0.3s ease-in-out infinite;
  }
  
  .vertical_loading_bars span:nth-of-type(3) {
    animation: grow 1s -0.15s ease-in-out infinite;
  }
  
  .vertical_loading_bars span:nth-of-type(4) {
    animation: grow 1s ease-in-out infinite;
  }
  
  @keyframes grow {
    0%, 100% { transform: scaleY(1); }
    50% { transform: scaleY(2); }
  }
  
  /* Cradle Style */
  .loading_cradle {
    width: 4em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .loading_cradle div {
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    background-color: #fc2f70;
  }
  
  .loading_cradle div:nth-of-type(1) {
    transform: translateX(-100%);
    animation: left-swing 0.5s ease-in alternate infinite;
  }
  
  .loading_cradle div:nth-of-type(3) {
    transform: translateX(-95%);
    animation: right-swing 0.5s ease-out alternate infinite;
  }
  
  @keyframes left-swing {
    50%, 100% { transform: translateX(95%); }
  }
  @keyframes right-swing {
    50% { transform: translateX(-95%); }
    100% { transform: translateX(100%); }
  }
  
  /* Dots Style */
  .loading_dots {
    width: 3em;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .loading_dots div {
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    background-color: #5E257D;
    animation: fade 0.8s ease-in-out alternate infinite;
  }
  
  .loading_dots div:nth-of-type(1) {
    animation-delay: -0.4s;
  }
  .loading_dots div:nth-of-type(2) {
    animation-delay: -0.2s;
  }
  @keyframes fade {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  
  /* Spinner Style */
  .loading_spinner {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    width:100px;
    height:100px;
  }
  
  .loading_spinner hr {
    border:0;
    margin:0;
    width:40%;
    height:40%;
    position:absolute;
    border-radius:50%;
    animation:spin 2s ease infinite;
  }
  
  .loading_spinner :first-child {
    background:#673184;
    animation-delay:-1.5s;
  }
  
  .loading_spinner :nth-child(2) {
    background: #794991;
    animation-delay: -1s;
  }
  
  .loading_spinner :nth-child(3) {
    background: #8a609f;
    animation-delay: -0.5s;
  }
  
  .loading_spinner :last-child {
    background: #9c77ad;
  }
  
  @keyframes spin {
    0%, 100% { transform: translate(0); }
    25% { transform: translate(160%); }
    50% { transform: translate(160%, 160%); }
    75% { transform: translate(0, 160%); }
  }
  
  /* Wave Style */
  .loading_wave {
    margin: 0px 50px 0px 0px;
    height: 30px;
    width: 90px;
    position: relative;
  }
  
  .loading_wave .wave_bars {
    background: #798AAF;
    position: absolute;
    width: 10px;
    border-radius: 10px;
    height: 10px;
    bottom: 0px;
  }
  
  .loading_wave .wave_bars:nth-child(1) {
    left: 0px;
    -webkit-animation: shoot 2s infinite ease-in-out 0.2s;
    animation: shoot 2s infinite ease-in-out 0.2s;
  }
  
  .loading_wave .wave_bars:nth-child(2) {
    left: 15px;
    -webkit-animation: shoot 2s infinite ease-in-out 0.4s;
    animation: shoot 2s infinite ease-in-out 0.4s;
  }
  
  .loading_wave .wave_bars:nth-child(3) {
    left: 30px;
    -webkit-animation: shoot 2s infinite ease-in-out 0.6s;
    animation: shoot 2s infinite ease-in-out 0.6s;
  }
  
  .loading_wave .wave_bars:nth-child(4) {
    left: 45px;
    -webkit-animation: shoot 2s infinite ease-in-out 0.8s;
    animation: shoot 2s infinite ease-in-out 0.8s;
  }
  
  .loading_wave .wave_bars:nth-child(5) {
    left: 60px;
    -webkit-animation: shoot 2s infinite ease-in-out 1s;
    animation: shoot 2s infinite ease-in-out 1s;
  }
  
  .loading_wave .wave_bars:nth-child(6) {
    left: 75px;
    -webkit-animation: shoot 2s infinite ease-in-out 1.2s;
    animation: shoot 2s infinite ease-in-out 1.2s;
  }
  
  @-webkit-keyframes shoot {
    0% {
      height: 0;
      opacity: 0;
    }
    50% {
      height: 40px;
      opacity: 1;
    }
    100% {
      height: 0;
      opacity: 0;
    }
  }
  
  @keyframes shoot {
    0% {
      height: 0;
      opacity: 0;
    }
    50% {
      height: 40px;
      opacity: 1;
    }
    100% {
      height: 0;
      opacity: 0;
    }
  }