.background-animate {
    background-size: 400%;
    -webkit-animation: bg-gradient 6s ease infinite;
    -moz-animation: bg-gradient 6s ease infinite;
    animation: bg-gradient 6s ease infinite;
}

@keyframes bg-gradient {
    0%,
    100% {
      background-position: right bottom;
    }
    50% {
      background-position: left top;
    }
}