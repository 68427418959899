@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Josefin Sans', 'sans-serif';
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
        margin: 0;
    }
    body {
        position: relative;
    }
    a {
        text-decoration: none;
    }
    p {
        margin: 0;
    }
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #EAEAEA;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(94, 37, 125, 0.3); 
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(94, 37, 125, 0.4); 
    }
    ::-ms-reveal {
        display: none
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@layer components {
    .whm-divider {
        @apply my-2 border-WHMBorder border-[1px] border-solid mx-auto
    }
    .navbar-icon-box {
        @apply flex justify-center items-center m-5 p-3 bg-WHMPurple/20 rounded-[50%] hover:rounded-xl hover:m-4 hover:p-4 hover:bg-WHMDarkPurple
        fill-[#5e257d] hover:fill-[#d6a156] transition-all duration-500 cursor-pointer shadow-halo
    }
    .navbar-icon-selected {
        @apply m-4 p-4 bg-WHMDarkPurple fill-[#d6a156]
    }
    .dashboard-menu-box {
        @apply w-full h-full bg-WHMLightGrey/50 border-r-[1px] border-WHMBorder
    }
    .dashboard-title-box {
        @apply w-full h-full bg-WHMWhite relative
        after:content-[""] after:absolute after:right-0 after:top-6 after:h-[90%]
        after:w-[1px] after:bg-WHMBorder
    }
    .dashboard-detail-box {
        @apply w-full h-full bg-WHMWhite
    }
    .item-title-with-bin {
        @apply w-full bg-WHMDarkPurple/40 p-2 rounded-[5px] text-WHMWhite italic 
        font-semibold flex justify-between hover:bg-WHMDarkPurple/70 fill-WHMDarkPurple 
        hover:fill-WHMWhite
    }
    .login-button {
        @apply bg-WHMDarkPurple/90 flex justify-center items-center
        text-WHMWhite rounded-[12px] w-full text-xl px-4 py-5
        disabled:bg-WHMPurple disabled:cursor-not-allowed drop-shadow-md
        hover:bg-WHMDarkPurple transition duration-150 ease-in-out
    }
    .blue-button {
        @apply bg-WHMBlue flex justify-center items-center
        text-WHMWhite rounded-[5px] w-full text-xl px-4 py-2 drop-shadow-md
        hover:bg-[#46b9ab] transition duration-150 ease-in-out
    }
    .red-outlined-button {
        @apply bg-WHMWhite flex justify-center items-center
        text-WHMRed rounded-[5px] w-full text-xl px-4 py-2 drop-shadow-md
        border-WHMRed/80 border-solid border-[2px] hover:text-WHMWhite
        hover:bg-WHMRed/80 transition duration-150 ease-in-out
    }
    .small-dark-purple-button {
        @apply bg-WHMDarkPurple w-fit px-3 py-1 text-[1em] flex justify-center items-center
        rounded-[5px] text-WHMWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .small-purple-button {
        @apply bg-WHMDarkPurple/60 w-fit px-3 py-2 text-[1em] flex justify-center items-center
        rounded-[5px] text-WHMWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .small-light-red-button {
        @apply bg-WHMRed/60 w-fit px-3 py-2 text-[1em] flex justify-center items-center
        rounded-[5px] text-WHMWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .delete-button {
        @apply bg-WHMRed w-fit mx-2 px-3 py-1 text-[1em] flex justify-center items-center
        rounded-[5px] text-WHMWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .save-button {
        @apply bg-WHMBlue w-fit mx-2 px-3 py-1 text-[1em] flex justify-center items-center
        rounded-[5px] text-WHMWhite cursor-pointer shadow-lg hover:scale-105
        transition-all duration-200
    }
    .golden-button {
        @apply bg-WHMGold/90 flex justify-center items-center
        text-WHMWhite rounded-[12px] w-full text-xl px-3 py-4
        drop-shadow-md hover:bg-WHMGold transition duration-150 ease-in-out
    }
    .modal-purple-button {
        @apply bg-WHMDarkPurple/90 flex justify-center items-center
        text-WHMWhite rounded-[12px] w-full text-xl py-3 drop-shadow-md
        hover:bg-WHMDarkPurple transition duration-150 ease-in-out
    }
    .user-profile-button {
        @apply bg-WHMDarkPurple/60 flex justify-center items-center 
        text-WHMWhite rounded-[5px] w-full text-xl px-4 py-2 drop-shadow-md 
        hover:bg-WHMDarkPurple/80 transition duration-150 ease-in-out
    }
    .mark-button {
        @apply bg-WHMBlue flex justify-center items-center text-WHMWhite 
        rounded-[5px] w-full text-xl px-4 py-2 mt-3 drop-shadow-md 
        hover:bg-[#46b9ab] transition duration-150 ease-in-out
    }
    .alternative-button {
        @apply bg-WHMBlue w-max flex justify-center items-center 
        rounded-[5px] px-3 py-2 mt-2 drop-shadow-md text-WHMWhite
        hover:bg-[#46b9ab] transition duration-150 ease-in-out
    }
    .workout-exercise-button {
        @apply bg-WHMBlue w-max h-max flex justify-center items-center 
        rounded-[5px] px-3 py-2 mt-2 ml-3 drop-shadow-md text-WHMWhite
        hover:bg-[#46b9ab] transition duration-150 ease-in-out
    }
    .remove-post-button {
        @apply bg-WHMWhite flex justify-center items-center
        border-solid border-[2px] border-WHMRed my-3
        text-WHMRed rounded-[5px] w-full text-xl px-4 py-2 drop-shadow-md
        hover:bg-WHMRed hover:text-WHMWhite transition duration-150 ease-in-out
    }
    .home-alternative-button {
        @apply text-WHMGreen w-full rounded-[5px] drop-shadow-sm
        border-solid border-[1px] border-WHMGreen py-2
        transition duration-150 ease-in-out hover:bg-WHMGreen/20
    }
}